import React from 'react'
import Helmet from 'react-helmet'

import { PageHeader, PageContent } from '../components/UI'

const PrivacyPolicy = props => {
  const page = props.data.markdownRemark

  return (
    <div className="page">
      <Helmet
        title={`${props.data.site.siteMetadata.title} | ${page.frontmatter.title}`}
        meta={[
          {
            name: 'description',
            content: page.frontmatter.description
          },
        ]}
      />

      <PageHeader title={page.frontmatter.title} />
      <PageContent>
        <div
          className="page-content"
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
      </PageContent>
    </div>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`